import * as React from "react";
import {Button} from "@material-ui/core";
import UserIcon from '@material-ui/icons/PersonAdd'
import withStyles from "@material-ui/core/es/styles/withStyles";

const styles = (theme:any) => ({
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
});


const CustomButton = (props: any) => {

    const { classes } = props;


    return (
        <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={props.nameChange}

        >
            <UserIcon className={classes.leftIcon}/>
           {props.buttonText}
        </Button>

    )


};

export default withStyles(styles)(CustomButton)

