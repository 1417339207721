// @ts-ignore
import jwt from 'jsonwebtoken'
import uuid from "uuid-by-string"
import axios from "axios"

export class Login {
    public username: any;
    public password: any;
    public jwtToken: any;
    public accessToken: any;


    async login(username: string, password: string) {

        return new Promise((resolve, reject)=> {

            this.username = username;
            this.password = password;

            this.convertUsernameToUUID()
                .then( async() => {
                    this.exchangeToken()
                        .then(async () => {
                            this.checkStilettoLogin()
                                .then(async () => {
                                    this.storeAccessToken()
                                        .then(async () => {
                                            resolve()
                                        })
                                })
                                .catch(error => {
                                    reject(error)
                                })

                        })
                        .catch(exchangeError => {
                            reject(exchangeError)
                        })
                });
        })
    }

    private convertUsernameToUUID() { // Converts to standardized UUID with a private key, this is what is stored to identify the user

        return new Promise(resolve => {
            this.jwtToken = jwt.sign({
                deviceUUID: uuid(this.username.toString().toLowerCase(), 5)
            },'5e380e02-457f-4d21-92a2-6e4e32cf6e11' );

            resolve();
        })
    }

    private exchangeToken() { // Sends the UUID to the DB and returns a accessToken if the user exists

        return new Promise((resolve, reject) => {

            axios.get('exchange', {
                headers: {
                    'x-jwt-token': this.jwtToken
                }
            })
                .then(result => {
                    this.accessToken = result.data.tokenResponse;
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })

        })
    }


    private checkStilettoLogin() { // Checks the given username and password and allows access to the reporting tool

        return new Promise((resolve, reject) => {

            axios.get('login', {
                headers: {
                    'x-jwt-token': this.accessToken
                },
                params:{
                    userName: this.username,
                    password: this.password
                }

            })
                .then(() => {
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })

        })

    }

    private storeAccessToken() { // Stores the access token in the browser cache until the windows is closed

        return new Promise(resolve =>  {
            sessionStorage.accessToken = this.accessToken;
            resolve()
        })
    }



}

export default Login
