import axios from "axios"


export class UserList {


    async getUser() {

        return new Promise(((resolve, reject) => {

            axios.get('users', {
                headers: {
                    'x-jwt-token': sessionStorage.getItem("accessToken")
                }
            })
                .then((userList) => {
                    resolve(userList.data.userList);
                })
                .catch(error => {
                    reject(error)
                })
        }))

    }

}
