import * as React from 'react';
import TextField from "@material-ui/core/TextField";
import {FormGroup} from "@material-ui/core";
import styles from './Login.module.css'
import Button from "@material-ui/core/Button";
import LoginAction from "../../API/Login";
import LoadingSpinner from "../Layout/LoadingSpinner/LoadingSpinner";


class Login extends React.Component<any, any> {


    state = {
        username: '',
        password: '',
        showError: false,
        loading: false
    };

    handleUserChange = (username: string) => (event: { target: { value: any; }; }) => {
        this.setState({
            [username]: event.target.value
        })
    };

    handlePasswordChange = (password: string) => (event: { target: { value: any; }; }) => {
        this.setState({
            [password]: event.target.value
        })
    };

    handleSubmit = (event: any) => {
        event.preventDefault();

        this.setState({loading: true});
        const LA = new LoginAction();

        LA.login(this.state.username, this.state.password)
            .then(() => {
                window.location.reload()
            })
            .catch(() => {

                this.setState({loading: false});
                this.setState({showError: true})

            })
    };

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    render() {

        return (
            <div className={styles['login']}>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <TextField
                            id="username"
                            label="Stiletto Username"
                            onChange={this.handleUserChange('username')}
                            margin="normal"
                            variant="outlined"
                            required={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            id="password"
                            label="Stiletto Password"
                            onChange={this.handlePasswordChange('password')}
                            margin="normal"
                            variant="outlined"
                            type="password"
                            required={true}
                        />
                    </FormGroup>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={!this.validateForm()}
                        type="submit"
                    >
                        Login
                    </Button>
                </form>

                {
                    (this.state.loading) ? <LoadingSpinner/> : (this.state.showError) ? 'Please check your username and password' : ''
                }


            </div>
        )
    }
}

export default Login
