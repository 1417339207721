import * as React from "react";
import Aux from '../Layout/Aux';
import CSelect from '../Layout/Selects/Select/cSelect'
import {Grid} from "@material-ui/core";
import CustomButton from '../Layout/Buttons/CustomButton'
import {UserList} from "../../API/UserList";
import {NewUserList} from "../../API/NewUserList";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import {IPermissionGroup, IUser} from "../../interfaces";
import {AddUser} from "../../API/AddUser";
import {PermissionGroups} from "../../API/PermissionGroups";
import {UserPermissions} from "../../API/UserPermissions";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {UpdatePermission} from "../../API/UpdatePermission";
import { withSnackbar } from 'notistack';


const styles = (theme: any) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightMedium,
        textDecoration: 'underline',
        color: '#e1e7ee'
    },

    expansion: {
        background: '#004c6c',
    }
});


class Permissions extends React.Component<any, any> {



    state = {
        finalUserName: '',
        finalUserID: '',
        existingUserName: '',
        currentGroup: null,
        currentNewName: {},
        newUser: false,
        UL: new UserList(),
        NUL: new NewUserList(),
        AU: new AddUser(),
        PM: new PermissionGroups(),
        UP: new UserPermissions(),
        PP: new UpdatePermission(),
        existingUsers: [],
        availableGroups: [],
        newUsers: [],
        expanded: 'panel1',
        showPermissions: false,
        currentPermissions: [],
    };


    componentDidMount() {
        this.getExistingUsers();
        this.getAvailableGroups();
    }




    // Existing Users

    getExistingUsers() {

        this.state.UL.getUser()
            .then((returnedUser: any) => {

                let nOptions: any = [];

                returnedUser.map((a: any) => {
                    nOptions.push({value: a.userUUID, label: a.userName})
                });
                this.setState({existingUsers: nOptions});
            })
            .catch()

    }

    existingUserChange = (newName: IUser) => {
        this.setState({
            existingUserName: newName,
            newUser: false,
            finalUserName: newName.label,
            finalUserID: newName.value,
            expanded: 'panel3',
            showPermissions: true,
            currentGroup: null,
            currentPermissions: []
        });
    };

    handleNewUserClick = () => {
        this.getNewUsers();
        this.setState({newUser: true, existingUserName: '', expanded: 'panel2'});
    };

    //  New Users

    getNewUsers() {
        this.state.NUL.getUsers()
            .then((returnedUser: any) => {

                let nOptions: any = [];

                returnedUser.map((a: any) => {
                    nOptions.push({value: a.userUUID, label: a.userName})
                });


                // The below pulls the unique usernames so names that are already added are not available to add again.

                let arrayold: any = this.state.existingUsers;

                const distinctArray = nOptions.filter((x: any)=>{
                    return !arrayold.some((t : any) =>t.value === x.value)
                });

                this.setState({newUsers: distinctArray, showPermissions: false});
            })
            .catch()

    }

    newUserSelected = (newUserName: IUser) => {
        this.setState({
            currentNewName: newUserName,
            finalUserID: newUserName.value,
            finalUserName: newUserName.label.toLowerCase()
        })
    };


    handleAddNewUserClick = () => {

        let iuser: IUser;

        iuser = {
            value: this.state.finalUserID,
            label: this.state.finalUserName
        };
        this.state.AU.addUser(iuser)
            .then(() => {
                // User added, repull the list from the db, close panel 2 and reopen panel 1
                this.setState({
                    existingUserName: iuser,
                    expanded: 'panel3',
                    showPermissions: true,
                    currentGroup: null,
                    currentPermissions: []
                });

                this.props.enqueueSnackbar(`${this.state.finalUserName} has been added`, {
                    variant: 'success',
                    autoHideDuration: 1500
                });
                this.getExistingUsers();
            })
            .catch()
    };


    // Panels
    panelExpansion = (panel: any) => (event: any, expanded: boolean) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };


    // Permissions

    // Groups

    getAvailableGroups() {

        this.state.PM.getGroups()
            .then((returnedGroups: any) => {

                let nGroups: any = [];
                returnedGroups.map((group: any) => {
                    nGroups.push({value: group.permissionGroupID, label: group.permissionGroupName})
                });

                this.setState({availableGroups: nGroups})
            })
    }



    // User Permissions


    loadUserPermissionsForGroup(permissionGroupID: number) {


        this.state.UP.getUserPermissions(this.state.finalUserID, permissionGroupID)
            .then((userPermission: any) => {

                let nPerms: any = [];

                userPermission.map((perm: any) => {
                   nPerms.push({permID: perm.permissionID, permName: perm.permissionName, active: perm.isActive})
                });

                this.setState({currentPermissions: nPerms})



            })
    }

    permissionGroupChange = (newGroup: IPermissionGroup) => {
        let permissionGroupID: number = Number(newGroup.value);
        this.setState({currentGroup: newGroup});
        this.loadUserPermissionsForGroup(permissionGroupID);
    };

    handlePermissionChange =  (perm: any) => (event: any) => {

        let currentList: any = this.state.currentPermissions;
        const nNewList: any = currentList.map((p: any) => p.permID === perm.permID ? {...p, active: !Boolean(perm.active)} : p);
        this.setState({currentPermissions: nNewList});
        this.updateUserPermission(perm.permID, !Boolean(perm.active));
    };

    updateUserPermission = (permID: number, active: boolean) => {

        const userUUID = this.state.finalUserID;

        this.state.PP.updatePermission(permID, active, userUUID)
            .then(() => {
                this.props.enqueueSnackbar("Permission Updated", {
                    variant: 'success',
                    autoHideDuration: 1500
                })
            })
            .catch(() => {
                this.props.enqueueSnackbar("Update Failed", {
                    variant: 'error',
                    autoHideDuration: 3000
                })
            })
    };






    render() {
        const {classes} = this.props;
        const {expanded} = this.state;
        // @ts-ignore
        return <Aux>
            <div style={{margin: 20}}>

                <ExpansionPanel
                    expanded={expanded === 'panel1'}
                    onChange={this.panelExpansion('panel1')}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon color={"action"}/>}
                        className={classes.expansion}>
                        <Typography className={classes.heading}>User Selection</Typography>
                    </ExpansionPanelSummary>
                    <div style={{padding: 30}}>
                        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>

                            <Grid item md={2} xs={4}>
                                <CSelect nameChanged={this.existingUserChange} value={this.state.existingUserName}
                                         options={this.state.existingUsers} placeHolder="Select or Search a User"/>
                            </Grid>


                            <Grid item md={1} xs={1}>
                                OR
                            </Grid>


                            <Grid item md={2} xs={2}>
                                <CustomButton buttonText="Add New User" nameChange={this.handleNewUserClick}/>
                            </Grid>

                        </Grid>
                    </div>
                </ExpansionPanel>
            </div>

            {(this.state.newUser) ?
                <div style={{margin: 20}}>
                    <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.panelExpansion('panel2')}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon color={"action"}/>}
                            className={classes.expansion}>
                            <Typography className={classes.heading}>Add New User</Typography>
                        </ExpansionPanelSummary>
                        <div style={{padding: 30}}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>

                                <Grid item md={2} xs={4}>
                                    <CSelect nameChanged={this.newUserSelected} value={this.state.currentNewName}
                                             options={this.state.newUsers} placeHolder="Select or Search a User"/>
                                </Grid>


                                <Grid item md={1} xs={1}>

                                </Grid>


                                <Grid item md={2} xs={2}>
                                    <CustomButton buttonText="Add User" nameChange={this.handleAddNewUserClick}/>
                                </Grid>

                            </Grid>
                        </div>
                    </ExpansionPanel>
                </div>
                :
                ''
            }

            {(this.state.showPermissions) ?

                <div style={{margin: 20}}>
                    <ExpansionPanel expanded={expanded === 'panel3'} onChange={this.panelExpansion('panel3')}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon color={"action"}/>}
                            className={classes.expansion}>
                            <Typography
                                className={classes.heading}>{'Edit Permissions for ' + this.state.finalUserName}</Typography>
                        </ExpansionPanelSummary>
                        <div style={{padding: 30}}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>

                                <Grid item md={3} xs={6}>
                                    <CSelect nameChanged={this.permissionGroupChange} value={this.state.currentGroup}
                                             options={this.state.availableGroups}
                                             placeHolder="Select or Search a Permission Group"/>
                                </Grid>
                            </Grid>

                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}
                                  style={{marginTop: 40}}>
                                <Grid item md={6} xs={12}>


                                    {(this.state.currentPermissions.length > 0) ?
                                        this.state.currentPermissions.map((permission: any) => {
                                            return <FormGroup row key={permission.permID}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={Boolean(permission.active)}
                                                            onChange={this.handlePermissionChange(permission)}
                                                            color="primary"
                                                            value={permission.active}
                                                        />
                                                    }
                                                    label={permission.permName}
                                                />
                                            </FormGroup>
                                        })
                                        :
                                        ''
                                    }

                                </Grid>

                            </Grid>
                        </div>
                    </ExpansionPanel>
                </div>


                :
                ''


            }


        </Aux>

    }


}

export default withStyles(styles)(withSnackbar(Permissions))
