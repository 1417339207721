import * as React from "react";
import Styles from './Header.module.css'



const Header = () => {


    return (
        <div className={Styles.Header}> <h1>Pavers Permission Management</h1></div>


    )

};

export default Header





