import axios from "axios"


export class PermissionGroups {


    async getGroups() {

        return new Promise(((resolve, reject) => {

            axios.get('permissions/groups', {
                headers: {
                    'x-jwt-token': sessionStorage.getItem("accessToken")
                }
            })
                .then((permissionGroups) => {
                    resolve(permissionGroups.data.permissionGroupModel);
                })
                .catch(error => {
                    reject(error)
                })
        }))

    }

}
