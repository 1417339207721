import React, { Component } from 'react';
import './App.css';
import Header from "./components/Layout/Header/Header";
import Login from "./components/Login/Login";
import Permissions from './components/Permissions/Permissions'

class App extends Component {
  render() {

    let loggedIn: boolean =false;
    if(sessionStorage.getItem("accessToken") !== null) {
      loggedIn = true;
    }

    return (
      <div className="App">
        <Header/>
        {(!loggedIn) ? <Login/> : <Permissions /> }
      </div>
    );
  }
}

export default App;
