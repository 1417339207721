
import axios from "axios"
import {IUser} from "../interfaces";


export class AddUser {
    async addUser(user: IUser) {
        return new Promise(((resolve, reject) => {

            axios.post(`users/${user.value}/${user.label}`, '' ,{
                headers: {
                    'x-jwt-token': sessionStorage.getItem("accessToken")
                },
            })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error)
                })
        }))

    }

}
