import axios from "axios"


export class UserPermissions {


    async getUserPermissions(userUUID: string, permissionGroupID: number) {

        return new Promise(((resolve, reject) => {

            axios.get(`permissions/${userUUID}/${permissionGroupID}`, {
                headers: {
                    'x-jwt-token': sessionStorage.getItem("accessToken")
                }
            })
                .then((userPermissions) => {
                    resolve(userPermissions.data.userPermissionModal);
                })
                .catch(error => {
                    reject(error)
                })
        }))

    }

}
