import axios from "axios"

export class UpdatePermission {
    async updatePermission(permID: number, active: boolean, UUID: string) {
        return new Promise(((resolve, reject) => {

            axios.post(`permissions/${permID}/${UUID}/${(active) ? 1 : 0}`, '' ,{
                headers: {
                    'x-jwt-token': sessionStorage.getItem("accessToken")
                },
            })
                .then(() => {
                    resolve();
                })
                .catch(error => {
                    reject(error)
                })
        }))

    }

}
