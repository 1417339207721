import React from 'react';
import Select  from 'react-select';



const CSelect =(props: any) => {

        const {value, options, nameChanged, placeHolder} = props;

        return (
            <Select
                value={value}
                isSearchable={true}
                placeholder={placeHolder}
                options={options}
                onChange={(e:any) => nameChanged(e)}
            />
        )
};

export default CSelect
