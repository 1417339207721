import React from 'react';
// @ts-ignore
import { loadCSS } from 'fg-loadcss';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import {Icon} from "@material-ui/core";
import classNames from 'classnames';



const styles = (theme: any) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    icon: {
        margin: theme.spacing.unit * 2,
    },
    iconHover: {
        margin: theme.spacing.unit * 2,
        '&:hover': {
            color: red[800],
        },
    },
});

class LoadingSpinner extends React.Component<any, any> {

    componentDidMount() {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
        );
    }


    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Icon className={classNames(classes.icon, 'fa fa-spinner fa-spin')}/>
            </div>

        );
    }
}

export default withStyles(styles)(LoadingSpinner);
